import React from "react";
import Img from "gatsby-image";

import { graphql } from "gatsby";
import styled from "styled-components";

import Container from "../components/container";
import SEO from "../components/SEO";

const StyledService = styled.div`
    .headerContainer {
        margin-top: 20px;
        .gatsby-image-wrapper {
            max-height: 250px;
        }
    }
    h1 {
        margin-top: 20px;
    }
    table {
        table-layout: fixed;
        border-collapse: collapse;
        width: 100%;
        @media (min-width: 576px) {
            width: 90%;
            margin: 0 auto;
        }
        tr {
            width: 100%;
        }
        td {
            padding: 1em 0 0 0;
            vertical-align: bottom;
            background-image: radial-gradient(black 1px, white 0px);
            background-size: 7px 7px;
            background-repeat: repeat-x;
            background-position: left bottom;
            span {
                background-color: #fff;
                &:first-child {
                    text-align: left;
                    font-weight: 700;
                    span {
                        padding-right: 0.25em;
                    }
                }
            }
            &:last-child {
                text-align: right;
                span {
                    padding-left: 0.25em;
                }
            }
        }
    }
`;

const service = ({ data, location }) => {
    const { naam, hoofdafbeelding, childContentfulDienstCategorieOmschrijvingTextNode, contentful_id, dienst } = data.contentfulDienstCategorie;
    return (
        <>
            <SEO title={naam} path={location.pathname} />
            <StyledService>
                {hoofdafbeelding && (
                    <div className="headerContainer">
                        <Img fluid={hoofdafbeelding.fluid} />
                    </div>
                )}
                <Container>
                    <h1>{naam}</h1>
                    {childContentfulDienstCategorieOmschrijvingTextNode && (
                        <div dangerouslySetInnerHTML={{ __html: childContentfulDienstCategorieOmschrijvingTextNode.childMarkdownRemark.html }} />
                    )}
                    {dienst && (
                        <>
                            <table>
                                <tbody>
                                    {dienst
                                        .sort((prev, next) => (prev.naam > next.naam ? 0 : -1))
                                        .map(dienst => (
                                            <tr key={`${contentful_id} ${dienst.naam}`}>
                                                <td>
                                                    <span>{dienst.naam}</span>
                                                </td>
                                                <td>
                                                    <span>{dienst.prijs}</span>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                            <p style={{ marginTop: "40px", fontSize: "14px" }}>
                                Prijzen onder voorbehoud van prijswijzigingen. <br /> Contacteer ons voor de meest actuele prijzen.
                            </p>
                        </>
                    )}
                </Container>
            </StyledService>
        </>
    );
};

export default service;

export const query = graphql`
    query($contentful_id: String!) {
        contentfulDienstCategorie(contentful_id: { eq: $contentful_id }) {
            naam
            childContentfulDienstCategorieOmschrijvingTextNode {
                childMarkdownRemark {
                    html
                }
            }
            hoofdafbeelding {
                fluid {
                    ...GatsbyContentfulFluid_noBase64
                }
            }
            contentful_id
            dienst {
                naam
                prijs
            }
        }
    }
`;
